import React from "react";
import ContactDialogue from "../components/ContactDialogue";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const ContactPage = () => {
    const pages = ['Home', 'About', 'Services', 'Contact'];

    return (
        <>
            <div>
                <Navbar pages={pages} />
                <div className="contact-section pt-5 pb-6">
                    <ContactDialogue />
                </div>
                <Footer />
            </div>
        </>
    );
}
 
export default ContactPage;