import React from "react";
import Navbar from "../components/Navbar";
import Jumbotron from "../components/Jumbotron";
import StickyBox from "../components/StickyBox";
import VideoBox from "../components/VideoBox";
import Testimonial from "../components/Testimonial";
import Footer from "../components/Footer";
import About from "../components/About";
import AutoplayCarousel from "../components/AutoplayCarousel";

const Home = () => {
    
    const pages = ['Home', 'About', 'Services', 'Contact'];
    
    return (
        <>
            <div>
                <Navbar pages={pages} />
                <Jumbotron />
                <StickyBox />
                <About />
                <VideoBox />
                <div className="container mt-6 container-carousel">
                    <p className="has-text-black is-size-4 has-text-centered mb-4" style={{ opacity: 0.7 }}>In collaboration with:</p>
                    <AutoplayCarousel />
                </div>
                <Testimonial />
                <Footer />
            </div>
        </>
    );
}
 
export default Home;