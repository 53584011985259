import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebook, faXTwitter } from "@fortawesome/free-brands-svg-icons";


const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <div className="footer-container py-3 pb-5">
            <div className="container has-text-white is-size-7 has-text-centered">
                
                <img src="/images/Didi-logo-02.png" alt="University Connect" width={90} className="my-3" />

                <div className="footer-link-container is-flex is-align-items-center is-justify-content-space-evenly mt-0 mb-5" style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                    <a className="footer-link-map" href="https://www.google.com" target="_blank" rel="noreferrer">Terms of Service</a>
                    <a className="footer-link-map" href="https://www.google.com" target="_blank" rel="noreferrer">Privacy Policy</a>
                    <a className="footer-link-map" href="https://www.google.com" target="_blank" rel="noreferrer">Security</a>
                    <a className="footer-link-map" href="https://www.google.com" target="_blank" rel="noreferrer">Sitemap</a>
                </div>

                <div style={{ marginLeft: 'auto', marginRight: 'auto' }}>

                    <div className="is-flex is-justify-content-center is-gap-2 mb-3">
                        <a className="footer-link-map" href="https://www.instagram.com/uni.connect_/" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faInstagram} size="2xl" />
                        </a>

                        <a className="footer-link-map" href="https://www.google.com" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faXTwitter} size="2xl" />
                        </a>

                        <a className="footer-link-map" href="https://www.google.com" target="_blank" rel="noreferrer">
                            <FontAwesomeIcon icon={faFacebook} size="2xl" />
                        </a>

                    </div>

                    <div>
                        <p className="copyright-text">&copy; {currentYear} University Connect. All rights reserved.</p>
                    </div>

                </div>
            </div>
        </div>
    );
}
 
export default Footer;