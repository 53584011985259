import React from "react";

const Jumbotron = () => {
    return (
        <section className="hero is-fullheight">
            <div className="hero-body">
                <div className="container has-text-centered">
                    <p className="title is-size-1 has-text-white mb-5 animate__animated animate__flipInX" style={{ lineHeight: '3.5rem' }}>Let's Make Connection <br></br> Across University</p>
                    <p className="subtitle is-size-5 has-text-white animate__animated animate__fadeInUp">Discover yourself with Education Fair and Lifelong Learning</p>

                    <a href="/contact" className="cta-button button is-warning is-light is-medium animate__animated animate__zoomIn">Register Now</a>
                </div>
            </div>
        </section>
    );
}
 
export default Jumbotron;