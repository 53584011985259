import React from "react";
import testimonialData from '../json/testimonial_data.json';
const Testimonial = () => {

    return (
        <>
        <div className="testimonial-container">
            <div className="container has-text-centered">
                <h1 className="testimonial-title is-size-3 mb-4">Kata Mereka yang telah <br></br> Mengikuti <span className="testimonial-highlight-text px-2" style={{ color: 'white' }}>University Connect</span></h1>

                <div className="testimonial-columns-container columns is-multiline is-flex is-align-items-stretch is-justify-content-center p-4 has-text-left">

                    { testimonialData.map((data, key) => {
                        return (
                            <div className="column is-half is-full-mobile" key={key}>
                                <div className="card-container card card-short">
                                    <div className="card-content">
                                        <div className="media">
                                            <div className="media-left">
                                                <figure className="image is-48x48">
                                                <img
                                                    src={data.imgPath}
                                                    alt={data.alt}
                                                    loading="lazy"
                                                />
                                                </figure>
                                            </div>
                                            <div className="media-content">
                                                <p className="testimonial-title title is-4 mb-1">{data.sekolah}</p>
                                                <p className="subtitle is-6">
                                                    {data.nama}
                                                    <span style={{ fontStyle: "italic", fontSize: '12px'}}> ({data.jabatan})</span>
                                                </p>
                                            </div>
                                        </div>

                                        <div className="testimonial-text content">
                                            {data.content}
                                            <br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
        </>
    );
}
 
export default Testimonial;