import React from 'react';

const VideoBox = () => {
    return (
        <>
            <div className="videobox-container columns container has-text-centered">

                <div className="column">
                    <h1 className="videobox-title is-size-3 mb-4">UNIFAIR RECAP</h1>

                    <div>
                        <video
                            width="95%"
                            poster='/images/thumbnail-video.jpg'
                            controls
                            style={{
                                borderRadius: '15px',
                            }}
                        >
                            <source src="/videos/edufair-highlight_cut-edition.mp4" type="video/mp4" />
                        </video>
                    </div>
                </div>

                <div className="column has-text-right has-text-centered-mobile">
                    <img src="/images/logo-sekolah.png" loading='lazy' alt="Kumpulan Logo Sekolah" width={450} />
                </div>
            </div>
        </>
    );
}
 
export default VideoBox;