import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChalkboardTeacher, faVanShuttle, faGlobe } from '@fortawesome/free-solid-svg-icons';

const StickyBox = () => {
    return (
        <div className="container stickybox-container px-2" style={{ marginTop: '-100px' }}>
            <div className="columns is-variable is-8 has-text-centered">
                <div className="column">
                    <div className="sticky-box-wrapper box" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <FontAwesomeIcon icon={faChalkboardTeacher} size="2xl" style={{ color: "#212D5A" }} />
                        <p className="m-2 is-size-3">University Fair</p>
                        <p className="sticky-box-desc">
                            Kami memfasilitasi pihak sekolah dan kampus dalam mempromosikan institusi pendidikannya agar dapat dikenal oleh calon siswa yang berminat sesuai dengan potensi dan kemampuannya
                        </p>
                    </div>
                </div>

                <div className="column">
                    <div className="sticky-box-wrapper box" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <FontAwesomeIcon icon={faVanShuttle} size="2xl" style={{ color: "#212D5A" }} />
                        <p className="m-2 is-size-3">University Tour</p>
                        <p className="sticky-box-desc">
                            Peran kami dalam membantu menyalurkan akses untuk melakukan kunjungan kampus dalam negeri khususnya PTN, hal ini kami lakukan supaya anak didik bisa memperoleh wawasan yang lebih luas lagi
                        </p>
                    </div>
                </div>

                <div className="column">
                    <div className="sticky-box-wrapper box" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', alignItems: 'center' }}>
                        <FontAwesomeIcon icon={faGlobe} size="2xl" style={{ color: "#212D5A" }} />
                        <p className="m-2 is-size-3">Overseas Program</p>
                        <p className="sticky-box-desc">
                           Kami berkomitmen untuk membantu serta memberikan akses seluas-luasnya bagi siswa dan siswi yang bercita-cita melanjutkan pendidikan ke luar negeri
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StickyBox;
