import React, { useState } from "react";
import { useNavigate } from "react-router";
import PropTypes from "prop-types";

const redirectHref = (page) => {
  const lowerCasePage = page.toLowerCase();

  if (lowerCasePage === 'home') return '/';
  if (lowerCasePage === 'about') return '/#about';
  return '/' + lowerCasePage;
}

const NavbarItem = ({ page }) => {
  const navigate = useNavigate();

  const handleClick = (e) => {
    if (page.toLowerCase() === 'about') {
      e.preventDefault(); // Prevent default navigation
      navigate('/'); // Navigate to the base route
      setTimeout(() => {
        const element = document.querySelector('#about');
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100); // Wait for navigation to complete
    }
  };

  return (
    <a className="navbar-item" href={redirectHref(page)} onClick={handleClick}>
      {page}
    </a>
  )
};

const NavbarBurger = ({ toggleMenu, active }) => (
  <button
    onClick={toggleMenu}
    className={`button navbar-burger ${active ? "is-active" : ""}`}
  >
    <span />
    <span />
    <span />
    <span />
  </button>
);

const Navbar = ({ pages = [] }) => {
  const [activeMenu, setActiveMenu] = useState(false);

  const toggleMenu = () => {
    setActiveMenu(!activeMenu);
  };

  return (
    <div className="container px-2">
        <nav className="navbar">
        <div className="navbar-brand py-2">
            <img src="/images/Didi-logo-01.png" alt="University Connect" width={90} />
            <NavbarBurger active={activeMenu} toggleMenu={toggleMenu} />
        </div>

        <div className={`navbar-menu-container navbar-menu ${activeMenu ? "is-active" : ""}`}>
            <div className="navbar-end">
            {pages.map((page) => (
                <NavbarItem page={page} key={page} />
            ))}
            </div>
        </div>
        </nav>
    </div>
  );
};

Navbar.propTypes = {
  pages: PropTypes.array.isRequired
};

export default Navbar;
