import React from "react";

const About = () => {
    return (
        <div id="about" className="container about-container my-6">
            <div className="columns is-vcentered is-flex-direction-column-tablet">
                <div className="column">
                    <p className="about-header is-size-1 mb-5">
                        Say Hello to
                        <span className="about-header-text-span">
                            <br></br>University Connect
                        </span>
                    </p>

                    <p className="about-description">
                        Kami merupakan lembaga organisasi fokus kepada event 
                        pendidikan di Indonesia berstandar nasional. Didirikan sejak 
                        2019 yang mana fokus kami dalam membantu setiap sekolah 
                        serta siswa dan siswi untuk memberikan kesempatan 
                        memilih perguruan tinggi terbaik guna mulai menyusun masa 
                        depan yang gemilang, kegiatan ini perlu di perluas dan di 
                        adakan serta melibatkan sekolah dan pihak - pihak terkait di 
                        tempat- tempat umum seperti Mall, Hotel dan tempat lainnya.
                    </p>
                </div>

                <div className="column has-text-right has-text-centered-mobile">
                    <img className="img-about" src="/images/graduation-man.png" alt="A young man with red outfit graduation" loading="lazy" />
                </div>
            </div>

        </div>
    );
}
 
export default About;