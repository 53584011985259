import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";

const ContactDialogue = () => {
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: "",
    });

    const [errors, setErrors] = useState({
        name: "",
        email: "",
        message: ""
    });

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submitSuccess, setSubmitSuccess] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Restrict numbers in the name field
        if (name === "name" && /[0-9]/.test(value)) {
            // If the value contains numbers, do not update the state
            return;
        }

        setFormData({
            ...formData,
            [name]: value
        });

        // Clear previous error on change
        setErrors({
            ...errors,
            [name]: ""
        });
    };

    const validateForm = () => {
        let valid = true;
        let newErrors = {};

        const nameRegex = /^[A-Za-z\s]+$/;
        if (!formData.name || !nameRegex.test(formData.name)) {
            valid = false;
            newErrors.name = "Name cannot contain numbers and should only include letters and spaces.";
        }

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!formData.email || !emailRegex.test(formData.email)) {
            valid = false;
            newErrors.email = "Please enter a valid email address.";
        }

        if (!formData.message) {
            valid = false;
            newErrors.message = "Message cannot be empty.";
        }

        setErrors(newErrors);
        return valid;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check if all fields are filled
        if (!validateForm) {
            return;
        }

        setIsSubmitting(true);
        try {
            const response = await fetch("https://universityconnect.id/api/contact", {  // replace with your deployed server URL
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(formData),
            });

            await response.json();
            
            if (response.ok) {
                setSubmitSuccess("Message sent successfully!");
                setFormData({
                    name: "",
                    email: "",
                    message: "",
                });

                setTimeout(() => {
                    setSubmitSuccess(null);
                }, 2000)
            } else {
                setSubmitSuccess("Failed to send message.");
            }
        } catch (error) {
            setSubmitSuccess(error.message);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="container is-max-desktop">
            <div className="has-text-centered" id="block-contact">
                <p className="is-size-1">Contact Us</p>
                <p>Any question or remarks? Just write us a message!</p>
            </div>

            <div className="custom-contact-box box is-flex is-justify-content-space-around my-5 p-6">
                <div>
                    <div className="block-white">
                        <p className="is-size-5 contact-sub-title"><b>Phone</b></p>
                        <p>+62 81291035367</p>
                    </div>

                    <div className="block-white">
                        <p className="is-size-5 contact-sub-title"><b>Email</b></p>
                        <p>info@universityconnect.id</p>
                    </div>

                    <div className="block-white">
                        <p className="is-size-5 contact-sub-title mb-1"><b>Social Media</b></p>

                        <div className="is-flex">
                            <a className="mr-2" href="https://www.instagram.com/uni.connect_/" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faInstagram} size="2xl" color="black" />
                            </a>
                            <a href="https://www.linkedin.com" target="_blank" rel="noreferrer">
                                <FontAwesomeIcon icon={faLinkedin} size="2xl" color="black" />
                            </a>
                        </div>
                    </div>
                </div>

                <div className="is-divider-vertical mx-6" data-content="OR"></div>

                <div>
                    <form onSubmit={handleSubmit}>
                        <div className="input-container is-flex is-justify-content-space-between is-gap-2">
                            <div className="field" style={{ width: '50%' }}>
                                <label className="label">Name</label>
                                <div className="control">
                                    <input
                                        className="input"
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        placeholder="John Doe"
                                        required
                                    />
                                </div>

                                {errors.name && <p className="help is-danger">{errors.name}</p>}
                            </div>

                            <div className="field email-address-field" style={{ width: '50%' }}>
                                <label className="label">Email Address</label>
                                <div className="control">
                                    <input
                                        className="input"
                                        type="email"
                                        name="email"
                                        value={formData.email}
                                        onChange={handleChange}
                                        placeholder="johndoe@example.com"
                                        required
                                    />
                                </div>
                                {errors.email && <p className="help is-danger">{errors.email}</p>}
                            </div>
                        </div>

                        {/* Body Message */}
                        <div className="field">
                            <label className="label">Message</label>
                            <div className="control">
                                <textarea 
                                    className="textarea"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    placeholder="Write your message here..." 
                                    required 
                                ></textarea>
                            </div>
                            {errors.message && <p className="help is-danger">{errors.message}</p>}
                        </div>

                        <div className="field">
                            <div className="control">
                                <label className="checkbox">
                                    <input
                                        type="checkbox"
                                        value="1"
                                        required
                                        className="mr-1"
                                    />
                                    By filling all the informations, you agree to be contacted by our expert consultant.
                                </label>
                            </div>
                        </div>

                        {/* Submit Button */}
                        <div className="field">
                            <div className="control">
                                <button className="button is-primary" type="submit" disabled={isSubmitting}>
                                    {isSubmitting ? "Submitting..." : "Submit"}
                                </button>
                            </div>
                        </div>
                    </form>

                    {/* Display Submission Result */}
                    {submitSuccess && (
                        <div className={`mt-3 notification ${submitSuccess === "Message sent successfully!" ? 'is-success' : 'is-danger'}`}>
                            {submitSuccess}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default ContactDialogue;
