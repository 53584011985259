import React from 'react';
import { Routes, Route } from 'react-router-dom';
import ContactPage from './pages/ContactUs';
import Home from './pages/Home';
import 'animate.css';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/contact' element={<ContactPage />} />
      </Routes>
    </div>
  );
}

export default App;
