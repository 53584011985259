import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";
import university_data from '../json/university_data.json';

const CustomImageWidth = (alt) => {
  if (alt.includes("Sampoerna") || alt.includes("Bina Nusantara")) {
    return 200;
  } else if (alt.includes("Atmajaya")) {
    return 80;
  }
  return 100;
}

const AutoplayCarousel = () => {
  return (
    <div className="container grid">
      <Splide
        options={{
          type: "loop",
          autoScroll: {
            pauseOnHover: false,
            pauseOnFocus: false,
            rewind: true, 
            speed: 1.5
          },
          arrows: false,
          pagination: false,
          fixedWidth: '150px',
          gap: '50px',
        }}

        extensions={{ AutoScroll }} // Use the AutoScroll extension
      >
        {university_data.map((data, key) => {
          return (
            <SplideSlide className="img-items" key={key}>
              <img src={data.imgPath} alt={data.alt} width={ CustomImageWidth(data.alt) } />
            </SplideSlide>
          )
        })}
      </Splide>
    </div>
  );
}
 
export default AutoplayCarousel;